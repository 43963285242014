import React, { useCallback, useState } from "react";
import { Production } from "./config/config";
//import { Development } from "./config/config";
import { useDropzone } from "react-dropzone";
import { ClipLoader } from "react-spinners";
import ImageDisplay from "./ImageDisplay";
import ErrorNotification from "./ErrorNotification";
import CheatSheetTable from "./CheatSheet";

const PDFUpload = () => {
  let [data, setData] = useState([]);
  const [error, setError] = useState(false);
  let [isProcessing, setIsProcessing] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (error) {
        setError(false);
      }
      if (acceptedFiles.length > 1) {
        alert("Only 1 file can be uploaded at a time.");
        return;
      }
      const uploadFile = async (file) => {
        setIsProcessing(true);
        const formData = new FormData();
        formData.append("file", file);

        try {
          const response = await fetch(`${Production.get_api_url()}upload`, {
            method: "POST",
            body: formData,
          });

          if (!response.ok) {
            throw new Error(
              "Network response was not ok: " + response.statusText
            );
          }

          const recv = await response.json();
          setData(recv);
          setIsProcessing(false);
        } catch (error) {
          console.error("Error:", error);
          setIsProcessing(false);
          setError(error.message);
          return;
        }
      };

      acceptedFiles.forEach(uploadFile);
    },
    [error]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "application/pdf",
    multiple: false,
    noClick: isProcessing,
    noDrag: isProcessing,
  });

  return (
    <>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <p>
          Drag 'n' drop a PDF here, or click to select one. Currently only 1 PDF
          at a time is allowed.
        </p>
        {isProcessing && (
          <div style={spinnerOverlayStyles}>
            <ClipLoader color="red" size={50} />
          </div>
        )}
      </div>
      <React.Fragment />
      <CheatSheetTable />
      <div className="content-container">
        {error && <ErrorNotification message={error} />}
        {data.length > 0 && !error ? (
          data.map((section, index) => (
            <div key={index} style={{ display: "flex", marginBottom: "20px" }}>
              <div style={{ flex: 1, marginRight: "20px" }}>
                <pre className="result">{section.image_text}</pre>
              </div>
              {section.image && (
                <div style={{ flex: 1, marginTop: "2%" }}>
                  <ImageDisplay
                    src={`data:${section.mime};base64,${section.image}`}
                    title={section.image_name}
                    width={section.width}
                    height={section.height}
                  />
                </div>
              )}
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

const spinnerOverlayStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};
export default PDFUpload;
