import React from 'react';

const CheatSheetTable = () => {
  return (
    <table style={styles.table}>
      <thead>
        <tr>
          <th style={styles.title} colSpan="4">Good Sizes Cheat-Sheet</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={styles.cell}>Article</td>
          <td style={styles.cell}>Half-Page ad</td>
          <td style={styles.cell}>Full-Page ad</td>
          <td style={styles.cell}>A4 Full-Page ad + 3mm bleed</td>
        </tr>
        <tr>
          <td style={styles.cell}>515 x 717 pts</td>
          <td style={styles.cell}>515 x 351 pts</td>
          <td style={styles.cell}>515 x 739 pts</td>
          <td style={styles.cell}>612 x 858 pts</td>
        </tr>
      </tbody>
    </table>
  );
};

const styles = {
  table: {
    width: 'auto',
    margin: '20px auto',
    borderCollapse: 'unset'
    //backgroundColor: 'none',
    //boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    //borderRadius: '8px',
    //overflow: 'hidden',
  },
  title: {
    backgroundColor: '#8510dc',
    color: 'white',
    padding: '12px',
    fontSize: '18px',
    textAlign: 'center',
  },
  cell: {
    padding: '12px',
    textAlign: 'center',
    border: '1px solid #ddd',
    fontSize: '16px'
  },
};

export default CheatSheetTable;

